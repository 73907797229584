import { defineStore } from 'pinia'

const useMenuStore = defineStore('menu', {
    state: () => ({
        mobileMenuOpen: false
    }),
    actions: {
        toggleMobileMenuOpen() {
            this.mobileMenuOpen = !this.mobileMenuOpen
        },
        closeMobileMenu() {
            this.mobileMenuOpen = false
        }
    }
})

export default useMenuStore
